
/**
 * Enhanced analytics utility functions
 * This file centralizes analytics tracking for SpeedType app to:
 * 1. Track user behavior comprehensively
 * 2. Maintain consistent event naming
 * 3. Support data-driven site optimization
 */

// Track page views with enhanced parameters
export const trackPageView = (path: string, title?: string) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', 'G-MEASUREMENT_ID', {
      page_path: path,
      page_title: title || document.title,
      send_page_view: true
    });
    
    console.log(`Page view tracked: ${path}`);
  }
};

// Track typing test events with detailed metrics
export const trackTypingTest = (eventName: string, data: Record<string, any> = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, {
      event_category: 'typing_test',
      ...data
    });
    
    console.log(`Typing test event tracked: ${eventName}`, data);
  }
};

// Track touch typing technique page events
export const trackTechniquePage = (eventName: string, data: Record<string, any> = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, {
      event_category: 'technique_page',
      ...data
    });
    
    console.log(`Technique page event tracked: ${eventName}`, data);
  }
};

// Track user engagement metrics
export const trackEngagement = (eventName: string, data: Record<string, any> = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, {
      event_category: 'user_engagement',
      ...data
    });
    
    console.log(`User engagement tracked: ${eventName}`, data);
  }
};

// Track user errors or difficulties
export const trackUserErrors = (errorType: string, data: Record<string, any> = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'user_error', {
      event_category: 'errors',
      error_type: errorType,
      ...data
    });
    
    console.log(`User error tracked: ${errorType}`, data);
  }
};

// Track search terms if implementing site search
export const trackSearch = (searchTerm: string, resultsCount: number = 0) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'search', {
      search_term: searchTerm,
      results_count: resultsCount
    });
    
    console.log(`Search tracked: ${searchTerm}, Results: ${resultsCount}`);
  }
};

// Track conversion events (e.g., completing a typing test)
export const trackConversion = (conversionType: string, value?: number, data: Record<string, any> = {}) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'conversion', {
      conversion_type: conversionType,
      value: value,
      ...data
    });
    
    console.log(`Conversion tracked: ${conversionType}`, data);
  }
};

// Enhanced event naming constants to ensure consistency
export const ANALYTICS_EVENTS = {
  // Typing test events
  TEST_STARTED: 'test_started',
  TEST_COMPLETED: 'test_completed',
  TEST_RESET: 'test_reset',
  DIFFICULTY_CHANGED: 'difficulty_changed',
  
  // Touch typing technique events
  TECHNIQUE_TAB_CHANGED: 'technique_tab_changed',
  TECHNIQUE_LINK_CLICKED: 'technique_link_clicked',
  
  // User engagement events
  PAGE_SCROLL_DEPTH: 'scroll_depth_reached',
  TIME_ON_PAGE: 'time_on_page',
  BUTTON_CLICK: 'button_click',
  EXTERNAL_LINK_CLICK: 'external_link_click',
  
  // Conversion events
  COMPLETED_FIRST_TEST: 'completed_first_test',
  COMPLETED_MULTIPLE_TESTS: 'completed_multiple_tests',
  IMPROVED_WPM: 'improved_wpm',
  
  // Error events
  TEST_ABANDONED: 'test_abandoned',
  FORM_VALIDATION_ERROR: 'form_validation_error'
};

// Utility to set up page tracking for SPA (React Router)
export const initializeAnalytics = (measurementId: string) => {
  // Set the correct measurement ID if provided
  if (measurementId && typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', measurementId);
    console.log(`Analytics initialized with ID: ${measurementId}`);
    return true;
  }
  return false;
};

